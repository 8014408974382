









import { Component, Vue, Watch } from 'vue-property-decorator';

import { DriverTypes } from './models/driver.model';
import PodList from './Pod/PodList.vue';
import Bill from './Bill/Bill.vue';
import Manifest from './Manifest/Manifest.vue';

@Component({
  components: {
    POD: PodList,
    BILL: Bill,
    MANIFEST: Manifest
  }
})
export default class Driver extends Vue {
  @Watch('$route', { immediate: true })
  onRouterChange() {
    const type = this.$route.params.type.toUpperCase();
    this.selectedDriverType = DriverTypes[type];

    this.driverName = this.driverChunks.find(item => item.type === type).name;
  }

  driverName = '';
  driverChunks = [
    { type: DriverTypes.POD, name: 'POD Check List' },
    { type: DriverTypes.BILL, name: 'BILL CONFIRM' },
    { type: DriverTypes.MANIFEST, name: 'MANIFEST' }
  ];

  selectedDriverType = '';

  constructor() {
    super();
  }
}
