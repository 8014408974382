


































import { Component, Vue, Watch } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { ManifestHeaders } from './models/ManifestHeaders';
import { statusModel } from './models/filters.model';
import ManifestFilters from './ManifestFilters/ManifestFilters.vue';

import { DriverModule } from '@/store/index';
import { DriverTypes } from '../models/driver.model';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import { WorkOrder } from '@/shared/models/wo.model';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { PermissionsService } from '@/shared/services/permission/permission.service';

@Component({
  components: { TmsTable, ManifestFilters, Loading, WoBaseModal }
})
export default class Manifest extends Vue {
  driverModule = DriverModule;
  columns: TableHeaders[] = ManifestHeaders;
  loading = false;
  disabled = false;
  type = '';

  selectedWo: WorkOrder = null;
  classes: string[] = [];
  perPage = 50;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    DriverModule.init();
    this.classes = generateRouterClasses(this.$route);
    this.type = this.$route.params.type.toUpperCase();
  }

  constructor() {
    super();
  }

  get tableData() {
    return this.driverModule.driverData.map(manifestItem => ({
      ...manifestItem,
      ...{
        statusLabel: statusModel.find(
          status => status.value === manifestItem.status
        ).text
      }
    }));
  }

  get isLastPage(): boolean {
    return !this.driverModule.meta?.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MOBILE ADMIN', '', 'View');
  }

  async search() {
    this.loading = true;
    try {
      await this.driverModule.search({
        driverType: DriverTypes[this.type]
      });
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
    }
  }

  action(rowData) {
    if (rowData.key === 'pod') {
      this.downloadPodFile(rowData?.data);
    } else if (rowData.key === 'row') {
      this.openWorkOrderDetail(rowData.data);
    }
  }

  async downloadPodFile(data: any) {
    try {
      this.loading = true;
      const result = await JobsService.getPodFile(data.jobId, data.driverId);
      if (result && result.data) {
        window.open(result.data);
      }
    } catch (e) {
      console.log(e);
      ToastHelper.show('Pod', 'POD file not found!', 5000, 'danger');
    } finally {
      this.loading = false;
    }
  }

  openWorkOrderDetail(data: WorkOrder) {
    this.selectedWo = data;
    this.$bvModal.show('wo-base-modal');
  }
}
