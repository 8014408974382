





































import { Component, Vue } from 'vue-property-decorator';

import { DriverModule } from '@/store/index';
import { DriverTypes } from '../../models/driver.model';

import { NumberType } from '../../models/filters/number-filter.model';
import {
  FilterConditions,
  FilterInput,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import SelectionFilter from '@/shared/components/form/SelectionFilter.vue';
import Loading from '@/shared/components/Loading.vue';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: {
    SelectionFilter,
    Loading
  }
})
export default class PodFilters extends Vue {
  excelData: any;
  loading = false;
  typeFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: NumberType.ContainerNumber
  });

  constructor() {
    super();
  }

  selectionTypes = {
    list: [{ value: NumberType.ContainerNumber, text: 'CONT#' }],
    autocompleteList: []
  };

  search() {
    DriverModule.setFilters(this.getFilters());
    this.$emit('search');
  }

  async excel() {
    try {
      this.loading = true;
      DriverModule.setFilters(this.getFilters());
      await DriverModule.excel(DriverTypes[this.$route.params.type]);

      if (DriverModule.excelData) {
        window.open(DriverModule.excelData.url);
      }
    } catch (err) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        err.message,
        5000,
        'danger'
      );
    } finally {
      this.loading = false;
    }
  }

  private getFilters(): string {
    return TransformFiltersToJson([this.typeFilter]);
  }
}
