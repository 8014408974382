



















































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { PodHeaders } from './models/PodHeaders';
import PodFilters from './PodFilters/PodFilters.vue';

import { DriverModule } from '@/store/index';
import { DriverTypes } from '../models/driver.model';
import { SmsService } from '@/shared/services/sms/sms.service';

import { ToastHelper } from '@/utils/toast.util';

import Loading from '@/shared/components/Loading.vue';
import { WorkOrder } from '@/shared/models/wo.model';
import { generateRouterClasses } from '@/shared/services/generate-router-classes';
import { DriverService } from '@/shared/services/mater-data/driver.service';
import { podValid } from '@/pages/Driver/Pod/@types/pod.model';
import { validationMixin } from 'vuelidate';
import WoBaseModal from '@/pages/Main/components/WoBaseModal.vue';
import { PermissionsService } from '@/shared/services/permission/permission.service';

Component.registerHooks(['validations']);
@Component({
  components: { TmsTable, PodFilters, Loading, WoBaseModal },
  mixins: [validationMixin]
})
export default class PodList extends Vue {
  driverModule = DriverModule;
  loading = false;
  type = '';

  selectedWo: WorkOrder = null;
  classes: string[] = [];

  columns: TableHeaders[] = PodHeaders;

  message = '';
  messageLoading = false;
  masks = {
    telNumMask: [
      '(',
      /\d/,
      /\d/,
      /\d/,
      ') ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ]
  };

  form = null;
  errorModal = false;
  perPage = 50;
  disabled = false;

  @Watch('$route', { immediate: true })
  onRouterChange() {
    DriverModule.init();
    this.type = this.$route.params.type.toUpperCase();
    this.classes = generateRouterClasses(this.$route);
    this.search();
  }

  constructor() {
    super();
  }

  created() {
    this.form = { country: '', phoneNumber: '', message: '' };
    this.disabled = PermissionsService.can('MOBILE ADMIN', '', 'View');
  }

  get tableData() {
    return this.driverModule.driverData;
  }

  get isLastPage(): boolean {
    return !this.driverModule.meta.lastKey;
  }

  async search() {
    this.loading = true;
    try {
      await this.driverModule.search({
        driverType: DriverTypes[this.type]
      });
    } catch (err) {
      ToastHelper.show(`${this.type}`, err.message, 5000, 'danger');
    } finally {
      this.loading = false;
    }
  }

  async action(rowData) {
    if (rowData.key === 'notice') {
      this.loading = true;
      try {
        const driverInfo = await DriverService.getDriverById(
          rowData.data.driverId
        );
        this.form.country = driverInfo.country;
        this.form.phoneNumber = driverInfo.cellPhone;
        this.$bvModal.show('notice-modal');
      } catch (e) {
        ToastHelper.show(
          `${this.$route.params.type}/${this.$route.name.replace(
            `${this.$route.params.type}-`,
            ''
          )}`,
          e.message,
          5000,
          'danger'
        );
      } finally {
        this.loading = false;
      }
    } else if (rowData.key === 'row') {
      this.openWorkOrderDetail(rowData.data);
    }
  }

  async sendMessage() {
    if (this.isFormInValid()) return;
    this.messageLoading = true;
    try {
      if (this.form.phoneNumber) {
        const data = {
          country: this.form.country,
          phoneNumber: this.form.phoneNumber.replace(/\D/g, ''),
          message: this.form.message
        };
        await SmsService.sendSms(data).then(() => {
          this.form.message = null;
        });
      }
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        'Message sent successfully',
        5000,
        'success'
      );
    } catch (e) {
      ToastHelper.show(
        `${this.$route.params.type}/${this.$route.name.replace(
          `${this.$route.params.type}-`,
          ''
        )}`,
        e.response.data?.error,
        8000,
        'danger'
      );
    } finally {
      this.messageLoading = false;
    }

    this.$bvModal.hide('notice-modal');
  }

  validateState(val: any) {
    if (this.$v && this.$v.form && this.$v.form[val]) {
      const { $dirty, $error }: any = this.$v.form[val];
      return $dirty ? !$error : null;
    }
  }

  get requiredFields(): Set<string> {
    return new Set(
      Object.keys(podValid).filter(fieldName => podValid[fieldName].required)
    );
  }

  isFormInValid() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      this.errorModal = true;
    }

    return this.$v.form.$anyError;
  }

  validations() {
    return {
      form: podValid
    };
  }

  openWorkOrderDetail(data: WorkOrder) {
    this.selectedWo = data;
    this.$bvModal.show('wo-base-modal');
  }
}
