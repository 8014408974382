import { BaseClass } from '../base/base.service';

export class SmsProvider extends BaseClass {
    constructor() {
        super('');
    }

    async sendSms(data) {
        const res = await this.post('text-message', data);
        return res.data;
    }
}

export const SmsService = new SmsProvider();
