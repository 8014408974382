export enum NumberType {
  OrderNumber = 'orderNumber',
  TrailNumber = 'trailNumber',
  ContainerNumber = 'containerNumber',
  ReferenceNumber = 'referenceNumber',
  PONumber = 'poNumber',
  MasterBillOfLading = 'masterBillOfLading',
  BillTo = 'billTo',
  PulloutLocation = 'pulloutLocation',
  DeliveryLocation = 'deliveryLocation',
  ReturnLocation = 'returnLocation'
}
