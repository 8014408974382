import { helpers, maxLength, required } from "vuelidate/lib/validators";

export const telNumber = helpers.regex(
    'telNumber',
    /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
);

export const podValid = {
    phoneNumber: { required, telNumber },
    message: { required, maxLength: maxLength(140) }
}